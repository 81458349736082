<template>
    <div class="alipaywith-box">
        <div class="wrp">
      <el-card class="box-card">
        <el-alert
          :title="$t('AliPayWithdrawal.TopTip')"
          type="warning"
          show-icon
        >
        </el-alert>
        
        <div class="forms-box">
          <div class="forms-item">
            <div class="lable">{{ $t('AliPayWithdrawal.WithdrawAccount') }}</div>
            <CSelect @change="getOption" :options="wOption" style="width:183px;margin:0">
                
            </CSelect>
          </div>
          <div class="forms-item">
            <div class="lable">
                {{ $t('AliPayWithdrawal.InputFullAccount') }}
                <el-tooltip
                        class="item"
                        effect="dark"
                        v-model="visi"
                        :manual="true"
                        :offset="-20"
                        :content="$t('AliPayWithdrawal.InputFullAccountTip')"
                        placement="bottom-start"
                      >
                        <i @mousemove="visiTip" @mouseout="hideTip" class="el-icon-warning" style="color: #e6a23c;padding:0;margin-left:.3rem"></i>
                      </el-tooltip>
                </div>
            <CInput
              style="margin: 0"
              type="text"
              v-model="alipayForm.wAccountName"
              @focus="visiTip"
              @blur="hideTip"
              autocomplete="curent-password"
            >
            </CInput>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t('BankWithdrawal.WithdrawAmount') }}</div>
            <CInput
              style="margin: 0"
              type="text"
              v-model="alipayForm.wAmount"
              autocomplete="curent-password"
              :invalid-feedback="errorBackAmount"
              :is-valid="validatorAmount"
              valid-feedback=""
            >
            </CInput>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t('BankWithdrawal.WithdrawCurrency') }}</div>
            <div class="huilv">{{ alipayForm.wCurrency }}</div>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t('AliPayWithdrawal.Rate') }}</div>
            <div class="huilv">{{ alipayForm.wHuilv }}</div>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t('BankWithdrawal.ServiceFee') }}</div>
            <div class="zfamt">{{ alipayForm.wFee }}</div>
          </div>
          <div class="forms-item">
            <div class="lable">{{ $t('BankWithdrawal.TransferAmount') }}</div>
            <div class="zfamt">{{ getAmount }}</div>
          </div>
          <div style="text-align: center; margin-top: 2rem">
            <!-- <el-button
              @click="submitWithdrawal"
              type=""
              class="sbtn"
              style="background: #00bea4; color: #fff; width: 100%;outline:0"
              >{{ $t('BankWithdrawal.Submit') }}</el-button
            > -->
            <button @click="submitWithdrawal" class="btn custer sbtn" type="button">{{ $t('BankWithdrawal.Submit') }}</button>
          </div>
        </div>
      </el-card>
    </div>
    </div>
</template>
<script>
import { getAliWithdrawInfo, withdraw } from "../../api/http"
export default {
    data() {
        return {
            errorBackAmount: "",
            flags: false,
            wOption: [],
            visi: false,
            alipayForm: {
                dAmount: 0,
                wAmount: null,
                wCurrency: "CNY",
                wFee: "3%",
                wAccountName: "",
                wHuilv: "",
                wAccountOption: ""
            }
        }
    },
    computed:  {
        getAmount() {
            let percentVal = "97%"
            let res = Number(this.alipayForm.wAmount) * Number(this.toPoint(percentVal)) * Number(this.alipayForm.wHuilv)
            return res.toFixed(2)
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
    visiTip() {
        this.visi = true
    },
    hideTip() {
        this.visi = false
    },
    // 提现
    submitWithdrawal() {
        if(this.alipayForm.wAccountName == "" || this.flags) {
            return
        }
        this.reqWithdraw(this.alipayForm.wAmount, "Alipay", this.alipayForm.wAccountName)
        this.alipayForm.wAmount = null
        this.alipayForm.wAccountName = ""
    },
    // 提現接口
    reqWithdraw(amt,pwy,act) {
        let data = {
            type: 'withdraw',
            uid: window.localStorage.getItem('uid'),
            amount: amt,
            pay_way: pwy,
            account: act
        }
        withdraw(data).then((res) => {
            if(res.code == 200) {
                this.$message.success(res.message)
                this.$router.push("/card-detail/account-flow")
            }else{
                this.$message.error(res.message)
            }
        })
    },
    // 获取提现信息
    getInfo() {
        let obj = {
            type: 'get_ali_withdraw_info',
            uid: localStorage.getItem('uid')
        }
        getAliWithdrawInfo(obj).then((res) => {
            console.log('res==>', res)
            if(res.code == 200) {
                this.alipayForm.wHuilv = res.data.rate
                this.alipayForm.wAccountOption = res.data.withdraw_account[0].open_id
                res.data.withdraw_account.forEach((item) => {
                    this.wOption.push(item.open_id)
                })
            }else {
                this.$message.error(res.message)
            }
        })
    },
    getOption(e) {
        console.log('option e==>', e)
        this.alipayForm.wAccountOption =  e.target.value
    },
    // 百分比轉爲小數
    toPoint(percent) {
      var str = percent.replace("%", "");
      //   console.log('str==>', str)
      str = str / 100;
      return str;
    },
    // 小数转百分比显示
    toPercent(point) {
      if (point == 0) {
        return 0;
      }
      var str = Number(point * 100).toFixed(2);
      str += "%";
      return str;
    },
    validatorAmount(val) {
      console.log("val==>", val);
      let reg = /^(\+)?\d+(\.\d+)?$/;
      if (val === null) {
        this.errorBackAmount = "";
        this.flags = false;
        return;
      }
      if (reg.test(val)) {
        this.errorBackAmount = "";
        this.flags = false;
        return true;
      } else {
        this.errorBackAmount = this.$t('BankWithdrawal.InvalidAmount');
        this.flags = true;
        return false;
      }
    },
    }
}
</script>
<style scoped>
.sbtn:hover{
  background: rgba(0,190,164, .8) !important;
}
.sbtn:focus{
  border-color: #00BEA4;
  box-shadow: 0 0 0 .25rem rgba(0,190,164, .5) !important;
}
.custer{
  background: #00bea4;
  color: #fff; 
  width: 100%;
  outline:0;
}
.alipaywith-box{
    padding-left: 2.6rem;
}
.wrp {
  width: 65%;
  margin: 1rem auto;
}
.forms-box {
  width: 360px;
  margin: 0.5rem auto;
}
.forms-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.funds-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.fund-t{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0.2rem;
}
.f-item{
    text-align: center;
}
</style>